<template>
    <div class="text-primary w-full">
        <div class=" h-screen outerbox  relative">

            <div class="px-5 pt-5">

                <carrouselVue :images="images" />
            </div>
            <div class="flex px-5 pt-5 justify-start items-center text-xl font-righteous ">
                <img class="max-h-5 pr-1" src="../assets/darts.png" />
                <span class="text-white pr-1">Live </span><span class="text-[#00e6f6]">Wins</span>
            </div>
            <div class="px-5 pt-4">
                <div class="infinite-container pb-6">
                    <div class="image-row" ref="imageRow" :style="{ animationDuration: animationDuration + 's' }">

                        <button @click="openIframe(item.gameId)" v-for="(item, index) in repeatedItems && repeatedItems"
                            :key="index" :style="{ flexShrink: 0 }" class=" flex-shrink-0 w-1/4 px-1 ">
                            <div v-if="item.amount > 0"></div>
                            <img :ref="el => (imageRefs[index] = el)"
                                :src="`https://client.qtlauncher.com/images/?id=${item.gameId}_en_US&type=logo-square&height=220&width=220`"
                                class=" rounded-md" alt="Player Image" />
                            <div class="text-start font-bold text-xs pt-1 text-white">{{ item.playerId.length > 9 ?
                                item.playerId.slice(0, 9) + '...' : item.playerId }}</div>

                            <div class="text-start font-bold text-green-500 text-xs">{{ item.amount.toFixed(2) }} USD
                            </div>


                        </button>

                    </div>
                </div>
            </div>







            <div class="flex px-4 items-center justify-start text-xl font-righteous">
                <img style="    width: 26px; 
    height: 24px;margin-right:4px" src="../assets/card-games-alt.png" />
                <span style="color: #00e6f6">Tournaments</span>

            </div>
            <div class="mx-5">
                <div class="flex overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             py-5">
                    <button @click="setModal(true, 'search-modal', '', '', true, tournament)"
                        class="flex-shrink-0 w-1/2 gap-2  " :key="i" v-for="(tournament, i) in tournaments">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="w-full rounded-md" :src="tournament.image &&
                                tournament.image" alt="" />

                        </div>
                    </button>

                </div>
            </div>
            <div class="flex px-4 items-center justify-start text-xl font-righteous">
                <img style="    width: 26px; 
    height: 24px;margin-right:4px; " src="../assets/card-games-alt.png" />

                <span class="text-white pr-1">Top </span><span style="color: #00e6f6">Providers</span>
                <div class="ml-auto">
                    <button :class="currentIndex != 0
                        ? 'border-[#4af3ff] bubble-inner-2 border-[2px] '
                        : 'border-profile-deposit bg-profile-deposit border-[2px] '
                        " class="rounded-xl w-11 ml-2 aspect-square" :disabled="currentIndex == 0
                            " @click="prevProvider">
                        <div class="flex justify-center">
                            <img :class="currentIndex != 0 ? 'filter-white' : ''
                                " src="../assets/Vector.png" />
                        </div>
                    </button>
                    <button @click="nextProvider" :disabled="currentIndex >= maxIndex" :class="currentIndex < maxIndex
                        ? 'border-[#4af3ff] bubble-inner-2 border-[2px]'
                        : 'border-profile-deposit bg-profile-deposit border-[2px]'
                        " class="rounded-xl w-11 ml-1 aspect-square">
                        <div class="flex justify-center items-center">
                            <img :class="currentIndex < maxIndex
                                ? 'filter-white'
                                : ''
                                " class="rotate-180" src="../assets/Vector.png" />
                        </div>
                    </button>
                </div>

            </div>
            <div class="mx-5 relative ">
                <div class="flex  transform transition-transform duration-500 ease-in-out overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             pt-5 pb-8">
                    <button @click="setModal(true, 'search-modal', 'provider', provider.id, false)"
                        class="flex-shrink-0 w-1/4   rounded-xl" style="min-width: 25%;" :key="i"
                        v-for="(provider, i) in visibleProviders">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="rounded-lg " :src="`https://lobby.qtlauncher.com/images/?id=${provider.id}_en_US&type=providers&version=1728053882027&theme=dark&format=avif${provider.id == 'SLG' ? '&newItem=true' : ''}
`" alt="" />
                            <!-- <div class="pb-3">
                                {{ getNumberGamesByProvider(provider) }} games
                            </div> -->
                        </div>
                    </button>
                </div>
            </div>
            <div class="flex px-4 items-center justify-start text-xl font-righteous">
                <img style="    width: 26px; 
    height: 24px;margin-right:4px" src="../assets/card-games-alt.png" />
                <span class="text-white pr-1">Last </span><span style="color: #00e6f6">Played</span>

            </div>
            <div class="mx-5">
                <div class="flex overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             py-5">
                    <button @click="openIframe1(game)" class="flex-shrink-0 w-1/4 gap-2  " :key="i"
                        v-for="(game, i) in dashboardStore.lastPlayed">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="w-full rounded-md" :src="game.images &&
                                game.images.length > 2
                                ? game.images[2].url.concat('&height=220&width=220')
                                : `https://client.qtlauncher.com/images/?id=${game.id}_en_US&type=logo-square&height=220&width=170`
                                " alt="" />

                        </div>
                    </button>

                </div>
            </div>

            <div class="flex px-4 items-center justify-start text-xl font-righteous">
                <img style="    width: 26px; 
    height: 24px;margin-right:4px; " src="../assets/card-games-alt.png" />
                <span class="text-white pr-1">Top </span><span style="color: #00e6f6">Games</span>
                <button @click="setModal(true, 'search-modal', 'top_games')"
                    class="ml-auto text-sm bg-slate-600 py-2 rounded-xl px-3 text-[white]">View All

                </button>
            </div>
            <div class="mx-5">
                <div class="flex overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             py-5">
                    <button @click="openIframe(game)" class="flex-shrink-0 w-1/4 gap-2  " :key="i"
                        v-for="(game, i) in topGames.slice(0, 10)">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="w-full rounded-md" :src="game.images &&
                                game.images.length > 2
                                ? game.images[2].url.concat('&height=220&width=220')
                                : `https://client.qtlauncher.com/images/?id=${game}_en_US&type=logo-square`
                                " alt="" />

                        </div>
                    </button>

                </div>
            </div>
            <div class="flex px-4 items-center justify-start text-xl font-righteous">
                <img style="    width: 26px; 
    height: 24px;margin-right:4px" src="../assets/card-games-alt.png" />
                <span class="text-white pr-1">Newly </span><span style="color: #00e6f6">Added</span>
                <button @click="setModal(true, 'search-modal', 'new_games')"
                    class="ml-auto text-sm bg-slate-600 py-2 rounded-xl px-3 text-[white]">View All

                </button>
            </div>
            <div class="mx-5">
                <div class="flex overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             py-5">
                    <button @click="openIframe1(game)" class="flex-shrink-0 w-1/4 gap-2  " :key="i"
                        v-for="(game, i) in new_games">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="w-full rounded-md" :src="game.images &&
                                game.images.length > 2
                                ? game.images[2].url.concat('&height=220&width=220')
                                : `https://client.qtlauncher.com/images/?id=${game.id}_en_US&type=logo-square&height=220&width=170`
                                " alt="" />

                        </div>
                    </button>

                </div>
            </div>
            <div class="flex px-4 items-center justify-start text-xl font-righteous">
                <img style="    width: 26px; 
    height: 24px;margin-right:4px" src="../assets/card-games-alt.png" />
                <span style="color: #00e6f6">Slots</span>
                <button @click="setModal(true, 'search-modal', 'slot_games')"
                    class="ml-auto text-sm bg-slate-600 py-2 rounded-xl px-3 text-[white]">View All

                </button>
            </div>
            <div class="mx-5">
                <div class="flex overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             py-5">
                    <button @click="openIframe1(game)" class="flex-shrink-0 w-1/4 gap-2  " :key="i"
                        v-for="(game, i) in slot_games">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="w-full rounded-md" :src="game.images &&
                                game.images.length > 2
                                ? game.images[2].url.concat('&height=220&width=220')
                                : `https://client.qtlauncher.com/images/?id=${game.id}_en_US&type=logo-square&height=220&width=170`
                                " alt="" />

                        </div>
                    </button>

                </div>
            </div>
            <div class="flex px-4 items-center justify-start text-xl font-righteous">
                <img style="    width: 26px; 
    height: 24px;margin-right:4px" src="../assets/card-games-alt.png" />
                <span class="text-white pr-1">Bonus </span><span style="color: #00e6f6">Buy</span>
                <button @click="setModal(true, 'search-modal', 'bonus_games')"
                    class="ml-auto text-sm bg-slate-600 py-2 rounded-xl px-3 text-[white]">View All

                </button>
            </div>
            <div class="mx-5">
                <div class="flex overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             py-5">
                    <button @click="openIframe1(game)" class="flex-shrink-0 w-1/4 gap-2  " :key="i"
                        v-for="(game, i) in bonus_games">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="w-full rounded-md" :src="game.images &&
                                game.images.length > 2
                                ? game.images[2].url.concat('&height=220&width=220')
                                : `https://client.qtlauncher.com/images/?id=${game.id}_en_US&type=logo-square&height=220&width=170`
                                " alt="" />

                        </div>
                    </button>

                </div>
            </div>


            <div class="flex px-4 items-center justify-start text-xl font-righteous">
                <img style="    width: 26px; 
    height: 24px;margin-right:4px" src="../assets/card-games-alt.png" />
                <span class="text-white pr-1">Live </span><span style="color: #00e6f6">Casino</span>
                <button @click="setModal(true, 'search-modal', 'live_casino')"
                    class="ml-auto text-sm bg-slate-600 py-2 rounded-xl px-3 text-[white]">View All

                </button>
            </div>
            <div class="mx-5">
                <div class="flex overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             py-5">
                    <button @click="openIframe1(game)" class="flex-shrink-0 w-1/4 gap-2  " :key="i"
                        v-for="(game, i) in live_casino">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="w-full rounded-md" :src="game.images &&
                                game.images.length > 2
                                ? game.images[2].url.concat('&height=220&width=220')
                                : `https://client.qtlauncher.com/images/?id=${game.id}_en_US&type=logo-square&height=220&width=170`
                                " alt="" />

                        </div>
                    </button>

                </div>
            </div>

            <div class="flex px-4 items-center justify-start text-xl font-righteous">
                <img style="    width: 26px; 
    height: 24px;margin-right:4px" src="../assets/card-games-alt.png" />
                <span class="text-white pr-1">Table </span><span style="color: #00e6f6">Games</span>
                <button @click="setModal(true, 'search-modal', 'table_games')"
                    class="ml-auto text-sm bg-slate-600 py-2 rounded-xl px-3 text-[white]">View All

                </button>
            </div>
            <div class="mx-5">
                <div class="flex overflow-x-auto no-scrollbar  max-w-[100%] gap-2 
             py-5">
                    <button @click="openIframe1(game)" class="flex-shrink-0 w-1/4 gap-2  " :key="i"
                        v-for="(game, i) in table_games">
                        <div class="text-[#424D71] text-[11px] font-roboto">
                            <img class="w-full rounded-md" :src="game.images &&
                                game.images.length > 2
                                ? game.images[2].url.concat('&height=220&width=220')
                                : `https://client.qtlauncher.com/images/?id=${game.id}_en_US&type=logo-square&height=220&width=170`
                                " alt="" />

                        </div>
                    </button>

                </div>
            </div>

            <div style="margin: 0 23px;" class="border-b-[0.5px] px-10  pb-8 border-[gray]"></div>
            <button @click="displayInfo(true)" class="relative w-full flex pr-2 pt-3 text-[white] justify-center">Gaming
                License <img style=" height: 23px;" src="../assets/info.png" alt="">
                <div class="absolute top-3 w-60 text-[9px] bg-slate-500 border-white rounded-md py-3 px-3 text-white flex flex-col justify-start"
                    v-if="isInfo == true">
                    <div class="relative" @click.stop>

                        <img @click="displayInfo(false)"
                            class="absolute right-[-5px] h-2 top-[-5px] border-[white] rounded-full"
                            src="../assets/cross.svg" alt="" />
                        <span class="py-3 px-3">The Gamehub LLC is an operator under the QTech gaming platform gaming
                            operated by
                            Mitratech
                            Curacao B.V., a company incorporated under the laws of Curaçao with company registration
                            number
                            146806. Its registered office is located at Zuikertuintjeweg Z/N (Zuikertuin Tower),
                            Willemstad,
                            Curaçao. The company is licensed by Cyberluck Curaçao N.V. (Curaçao eGaming) under license
                            number 1668/JAZ, authorized by the Government of Curaçao.</span>
                    </div>

                </div>
            </button>

            <div class="flex flex-col pt-3 text-[white] justify-center ">
                <div class="block pb-3">
                    Terms of Service || Privacy Policy</div>
                <div class="pb-2">Support: <a href="t.me/TheGameHubSupportBot" target="_blank">@TheGameHubSupportBot</a>
                </div>
                <div class="pt-1 pb-8">
                    <div class="flex justify-evenly">
                        <div><img src="../assets/+18.png" alt=""></div>
                        <div><a href="https://www.gambleaware.org/home"><img src="../assets/begamble.png" alt=""></a>
                        </div>
                    </div>
                </div>
            </div>


            <div class="px-5">
                <!--Put inside a table component-->

                <!-- <div v-if="dashboardStore.gameUrl != ''">
                    <div style="z-index: 9998;" class="w-[100vw]  h-[100vh] bg-black fixed left-0 top-0 ">
                        <button style="position:absolute; z-index: 9999;"
                            @click="exitGame[dashboardStore.setGameUrl(), showIframe = false]">close</button>
                        <iframe class="fixed left-0 top-0 h-[100vh] w-[100vw] z-50" :src="dashboardStore.gameUrl"
                            frameborder="0">

                        </iframe>


                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { useDashboardStore } from '@/store/dashboardStore'
import {
    computed, ref, onMounted, onUnmounted, watch, nextTick

} from 'vue'
import carrouselVue from './carrouselVue.vue'
import axios from 'axios'

import { data, auth, coinSupported, supabase } from '@/main'

import { componentKey } from '@/App.vue'

const triggerReload = () => {
    dashboardStore.setComponentKey()
}

const showIframe = ref(true);

const closeIframe = () => {
    showIframe.value = false;
};

const isInfo = ref(false)

const displayInfo = (val) => {
    console.log("buttonClicked", val)
    isInfo.value = val
}
// function showBackButton() {
//     // window.location.reload();
//     window.parent.postMessage("showBackButton", "*");
// }

// async function hideBackButton() {
//     window.parent.postMessage("hideBackButton", "*");
//     dashboardStore.setLoading(false)
//     dashboardStore.setLoadingModal(false)
//     triggerReload()


//     console.log("back Clicked", dashboardStore.loading)

// }

const imageRefs = ref([]);
const imageRow = ref(null);

const calculateScrollAnimation = () => {
    const row = imageRow.value;
    if (row) {
        nextTick(() => {
            const totalWidth = row.scrollWidth; // Calculate total width of child elements
            const duration = totalWidth / 50; // Adjust scrolling speed (50px/s)
            console.log("Duration:", duration, "Total Width:", totalWidth);

            // Update CSS variables for animation
            row.style.setProperty("--scroll-distance", `${totalWidth}px`);
            row.style.setProperty("--scroll-duration", `${duration}s`);

            // Update local state for reactivity (if necessary)
            animationDuration.value = duration;
        });
    }
}

const allowedProviderIds = [
    'SLG',
    'PPC',
    'EVO',
    'HAK',
    'SPB',
    'BPG',
    'HAB',
    'NLC',
    'TK',
    'BTL',
    'EVP',
    'EZU',
    'FNG',
    'SWC',
    'GA',
    'PPL',
    'MSG',
    'MOB',
    'NAG',
    'NGE',
    'SAG',
    'SM',
    'SMS',
    'TRB',
    'VGL',
    'WAZ',
    'WOO',
    '1X2',
    '7ML',
    '7MC',
    'BTG',
    'NE',
    'RED',
    'SWL',
    'EYC'
]

// window.addEventListener("message", async (event) => {
//     if (event.data === "onClickBack") {
//         // Your back navigation logic here, e.g., close the game, or go
//         showIframe.value = false
//         hideBackButton()
//         dashboardStore.setGameUrl('')
//         dashboardStore.setShowIframe(false)
//         dashboardStore.setLoading(false)
//         console.log("back Clicked", dashboardStore.loading)

//     }
// });
// Define props
const gameProviders = [

    { id: 'SLG' },
    {
        name: 'Pragmatic Play Casino',
        id: 'PPC',
        img: 'https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg'
    },
    {
        name: 'Evolution Gaming',
        id: 'EVO',
        img: 'https://cdn2.softswiss.net/logos/providers/white/evolution.svg'
    },
    {
        name: 'Hacksaw Gaming',
        id: 'HAK',
        img: 'https://cdn2.softswiss.net/logos/providers/white/hacksaw.svg'
    },
    {
        name: 'Spribe',
        id: 'SPB',
        img: require('@/assets/SPB.png')
    },
    { id: 'YGG' },
    { id: 'BPG' },
    {
        name: 'Habanero',
        id: 'HAB',
        img: 'https://cdn2.softswiss.net/logos/providers/white/habanero.svg'
    },
    {
        name: 'Nolimit City',
        id: 'NLC',
        img: 'https://cdn2.softswiss.net/logos/providers/white/nolimit.svg'
    },
    {
        name: 'Thunderkick',
        id: 'TK',
        img: 'https://cdn2.softswiss.net/logos/providers/white/thunderkick.svg'
    },
    { id: 'BTL' },
    {
        name: 'Evoplay',
        id: 'EVP',
        img: 'https://cdn2.softswiss.net/logos/providers/white/evoplay.svg'
    },
    { id: 'EZU' },
    {
        name: 'Fantasma Games',
        id: 'FNG',
        img: 'https://cdn2.softswiss.net/logos/providers/white/fantasma.svg'
    },
    { id: 'SWC' },
    {
        name: 'GAMEART',
        id: 'GA',
        img: 'https://cdn2.softswiss.net/logos/providers/white/gameart.svg'
    },
    {
        name: 'Pragmatic Play Live',
        id: 'PPL',
        img: 'https://cdn2.softswiss.net/logos/providers/white/pragmatic.svg'
    },
    { id: 'MSG' },
    { id: 'MOB' },
    { id: 'NAG' },
    { id: 'NGE' },
    { id: 'SAG' },
    {
        name: 'Slotmill',
        id: 'SM',
        img: 'https://cdn2.softswiss.net/logos/providers/white/slotmill.svg'
    },
    { id: 'SMS' },
    { id: 'TRB' },
    { id: 'VGL' },
    {
        name: 'Wazdan',
        id: 'WAZ',
        img: 'https://cdn2.softswiss.net/logos/providers/white/wazdan.svg'
    },
    { id: 'WOO' },
    { id: '1X2' },
    { id: '7ML' },
    { id: '7MC' },
    {
        name: 'Big Time Gaming',
        id: 'BTG',
        img: 'https://cdn2.softswiss.net/logos/providers/white/bigtimegaming.svg'
    },
    {
        name: 'NetEnt',
        id: 'NE',
        img: 'https://cdn2.softswiss.net/logos/providers/white/netent.svg'
    },
    {
        name: 'Red Tiger',
        id: 'RED',
        img: 'https://cdn2.softswiss.net/logos/providers/white/redtiger.svg'
    },
    { id: 'SWL' },
    { id: 'EYC' }
];
// Use the store

const dashboardStore = useDashboardStore()
// const baseDataRef = doc(db, 'aBase/baseInf')
//  const baseData = await getDoc(baseDataRef)
//  const coinSupported = baseData.data().coinSupported
const setModal = async (val, name, list, provId, isTournaments, selectedTournament) => {
    dashboardStore.setModalOpen(val, name)
    if (name == 'register-modal') dashboardStore.setHeader('Sign Up')
    else if (name == 'login-modal' || name == 'forget-modal') dashboardStore.setHeader('Login')
    else if (name == 'profile-modal') { dashboardStore.setSelectedTab(2), dashboardStore.setHeader('Profile') }
    else if (name == 'deposit-modal') { dashboardStore.setSelectedTab(3), dashboardStore.setHeader('Deposit') }
    else if (name == 'search-modal') {

        if (isTournaments == true) {
            dashboardStore.setIsTournaments(true)
            dashboardStore.setSelectedTournament(selectedTournament)
            dashboardStore.setLoading(false)
            console.log("tournaments", true)
        } else {
            console.log("tournaments", false)
            dashboardStore.setIsTournaments(false)
        }
        if (list == 'table_games')
            dashboardStore.setSelectedGT('TABLEGAMES')
        if (list == 'slot_games')
            dashboardStore.setSelectedGT('SLOTS')
        if (list == 'live_casino')
            dashboardStore.setSelectedGT('LIVECASINO')
        if (list == 'top_games') {
            dashboardStore.setGameList('top_games')
        }
        if (list == 'provider') {
            dashboardStore.setSelectedPV(provId)
        }

        if (list != 'provider') {
            dashboardStore.setGameList(list)
        } else {
            const { data, error } = await supabase
                .from('qt_games')
                .select('*') // Select all columns or specific ones
                .ilike('provider_id', `%${provId}%`)
                .order('release_date', { ascending: false }).limit(50); // Sort by release_date in descending order
            if (error) {
                console.error('Error fetching sorted games:', error);
            } else {
                console.log('Sorted games:', data);
                dashboardStore.setGameList2(data)
                dashboardStore.setSearchList(data)
                dashboardStore.setLoading(false)
            }
        }
        dashboardStore.setLoading(false)
        console.log("hereee"), dashboardStore.setSelectedTab(1), dashboardStore.setLoadingModal(true), dashboardStore.setHeader('Search'), dashboardStore.setLoadingModal(false)
    }
}
// Define a computed property for category
const animationDuration = ref(10)

const iframeOpened = ref(false)
const params = ref({ size: 14 })
const currentIndex = ref(0)
// Define the method to set the category
const itemsPerView = ref(4)
const visibleProviders = computed(() => {
    const start = currentIndex.value * itemsPerView.value;
    return gameProviders.slice(start, start + itemsPerView.value);
})

const maxIndex = computed(() => {

    return Math.ceil(gameProviders.length / itemsPerView.value) - 1;

})

const nextProvider = () => {
    if (currentIndex.value < maxIndex.value) {
        currentIndex.value++;
    }
}

const prevProvider = () => {
    if (currentIndex.value > 0) {
        currentIndex.value--;
    }
}
const reset = ref(null)
const providerOrder = ['HAK', 'EVO', 'PPC'];


const setCategory = val => {
    dashboardStore.resetLinkHistory()
    // Store the current category for reset purposes
    reset.value = dashboardStore.category

    // Update the category in the dashboard store
    dashboardStore.setCat(val)

    // Conditional logic based on the category value

    // Reset the dashboard page to the first page
    dashboardStore.setPageDashboard(0)
}
let currentPage = ref(1)
const itemsPerPage = 3
const aggregatedBetsAndPayouts = computed(() => {
    console.log("BETS", dashboardStore.livebets.length != 0 ? dashboardStore.livebets : 0)
    // Filter out transactions where type is not ROLLBACK or other disallowed types.
    const filteredTransactions = dashboardStore.livebets.length != 0 && dashboardStore.livebets.filter(
        (transaction) => transaction.type === "PAYOUT"
    );
    console.log("TRANsss", filteredTransactions)

    const reversedArray = filteredTransactions && [...filteredTransactions];
    console.log("TRANSACTIOS", reversedArray)
    // Reduce to aggregate by playerGameRoundId
    const aggregated = reversedArray && reversedArray.reduce((acc, transaction) => {
        const roundId = transaction.playerGameRoundId;
        console.log("TRAN", transaction)
        // Ensure we have an entry for this roundId
        if (!acc[roundId]) {
            acc[roundId] = {
                playerGameRoundId: roundId,    // Include playerGameRoundId for clarity
                playerId: transaction.username,  // Store playerId
                gameId: transaction.gameId,      // Store gameId
                amount: 0,

            };
        }

        // Accumulate based on type

        if (transaction.type === "PAYOUT" && transaction.amount > 0) {
            console.log("AMOUNT", transaction.amount)
            acc[roundId].amount += parseFloat(transaction.amount);
        }
        console.log("ACC", acc)

        return acc;
    }, {});
    console.log("AGG", aggregated)
    console.log("AGG", aggregated && Object.values(aggregated).filter(entry => entry.payout !== 0))
    // Convert the aggregated object into an array of objects and filter out those with payout 0
    return aggregated && Object.values(aggregated).filter(entry => entry.payout !== 0);
});





const total_pages = computed(() => {
    return Math.ceil(gameProviders.length / itemsPerPage)
})

const paginated_items = computed(() => {
    const startIndex = (currentPage.value - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return gameProviders.slice(startIndex, endIndex)
})
function getPaginatedItems(array, page) {
    const startIndex = (page - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    return array.slice(startIndex, endIndex)
}


function goToNextPage() {
    if (currentPage.value < total_pages.value) {
        currentPage.value++
    }
}

const getNumberGamesByProvider = provider => {
    return dashboardStore.searchList.filter(item => item.provider_id == provider.id).length
}

function goToPreviousPage() {
    if (currentPage.value > 1) {
        currentPage.value--
    }
}
const page_provider = ref(0)
const itemBatches = computed(() => {
    if (items.value) {
        const batchSize = 20
        const batches = []
        for (let i = 0; i < items.value.length; i += batchSize) {
            batches.push(items.value.slice(i, i + batchSize))
        }
        return batches
    } else return null
})
const setReset = computed(() => {
    if (reset.value != dashboardStore.category) {
        return {
            transform: `translateX(-${currentIndex.value * 100}%)`,
            transition: 'transform 0.5s ease' // Apply transition effect
        }
    } else {
        return {
            transform: `translateX(-0%)`,
            transition: 'transform 0.5s ease' // Apply transition effect
        }
    }
})
// First, define all_games_ to compute the list from the store
const all_games = computed(() => {
    return dashboardStore.searchList; // Assuming gameList is an array
});

const topGames = computed(() => {

    const ids = dashboardStore.popularGames.map(item => item.id)
    return ids.sort((a, b) => {
        const indexA = providerOrder.indexOf(a.provider_id);
        const indexB = providerOrder.indexOf(b.provider_id);

        // Items in providerOrder should come first, sorted by their index
        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        }
        // Items in providerOrder come before items not in the list
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;

        // For items not in providerOrder, keep their original order
        return 0;
    });
})

const table_games = ref([])
const new_games = ref([])
const slot_games = ref([])
const live_casino = ref([])
const tournaments = ref([])
const bonus_games = ref([])
const newGames = async () => {
    const { data, error } = await supabase
        .from('qt_games')
        .select('*') // Select all columns or specific ones
        .in('provider_id', allowedProviderIds)
        .order('release_date', { ascending: false }).limit(10); // Sort by release_date in descending order

    if (error) {
        console.error('Error fetching sorted games:', error);
    } else {
        console.log('Sorted games:', data);
        new_games.value = data
    }
}

const tableGames = async () => {
    try {
        const { data, error } = await supabase
            .from('qt_games')
            .select('*') // Select all columns or specific ones
            .ilike('category', '%TABLEGAME%')
            .in('provider_id', allowedProviderIds)
            .limit(10) // Case-insensitive pattern match
        if (error) {
            console.error('Error fetching games with matching category:', error)
            return []
        }

        console.log('Matching games:', data)
        table_games.value = data
        return data
    } catch (err) {
        console.error('Unexpected error:', err)
        return []
    }


}



const slotGames = async () => {
    try {
        const { data, error } = await supabase
            .from('qt_games')
            .select('*') // Select all columns or specific ones
            .ilike('category', '%SLOT%')
            .in('provider_id', allowedProviderIds)
            .limit(10) // Case-insensitive pattern match
        console.log("SLOT", data)
        if (error) {
            console.error('Error fetching games with matching category:', error)
            return []
        }

        console.log('Matching games:', data)
        slot_games.value = data
        return data
    } catch (err) {
        console.error('Unexpected error:', err)
        return []
    }


}

const getTournaments = async () => {
    try {
        const { data, error } = await supabase
            .from('qt_tournaments')
            .select('*') // Select all columns or specific ones
        if (error) {
            console.error('Error fetching tournaments', error)
            return []
        }
        console.log("tournaments", data)
        tournaments.value = data
        return data
    } catch (error) {
        console.error('Error fetching tournaments:', error)
        return []
    }
}

const liveCasino = async () => {
    try {
        const { data, error } = await supabase
            .from('qt_games')
            .select('*') // Select all columns or specific ones
            .ilike('category', '%LIVECASINO%')
            .in('provider_id', allowedProviderIds)
            .limit(10) // Case-insensitive pattern match
        console.log("LIVECASINO", data)
        if (error) {
            console.error('Error fetching games with matching category:', error)
            return []
        }

        console.log('Matching games:', data)
        live_casino.value = data
        return data
    } catch (err) {
        console.error('Unexpected error:', err)
        return []
    }


}
const bonusGames = async () => {


    const { data, error } = await supabase
        .from('qt_games')
        .select('*')
        .in('provider_id', allowedProviderIds)

        .not('features', 'is', null) // Exclude NULL values
        .limit(50); // Ensure proper JSON format

    const data_ = data.filter((item) => item.features && item.features.some(feature => feature.id == 'BONUS_BUY'))
    const result = data_.slice(0, 10)
    if (error) {
        console.error('Error fetching games with BONUS_BUY feature:', error);
    } else {
        bonus_games.value = result

        console.log('Games with BONUS_BUY feature:', data);
    }

}

// const bonusGames = computed(() => {
//     let resultArray = [...all_games.value]
//     console.log("result Arr", resultArray)
//     const res_dm = resultArray ? resultArray.filter(item => item.features && item.features.filter(item => item.id == 'BONUS_BUY')) : null
//     return res_dm.sort((a, b) => {
//         const indexA = providerOrder.indexOf(a.provider_id);
//         const indexB = providerOrder.indexOf(b.provider_id);

//         // Items in providerOrder should come first, sorted by their index
//         if (indexA !== -1 && indexB !== -1) {
//             return indexA - indexB;
//         }
//         // Items in providerOrder come before items not in the list
//         if (indexA !== -1) return -1;
//         if (indexB !== -1) return 1;

//         // For items not in providerOrder, keep their original order
//         return 0;
//     });
// })

const liveCasinoGames = computed(() => {
    let resultArray = [...all_games.value]
    const res_dm = resultArray.filter(item => item.category.includes('LIVECASINO'))
    return res_dm.sort((a, b) => {
        const indexA = providerOrder.indexOf(a.provider_id);
        const indexB = providerOrder.indexOf(b.provider_id);

        // Items in providerOrder should come first, sorted by their index
        if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
        }
        // Items in providerOrder come before items not in the list
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;

        // For items not in providerOrder, keep their original order
        return 0;
    });
})



const items = computed(
    () => {
        let resultArray = [...all_games.value] // Create a shallow copy of the array

        if (category.value == 5) {
            // Apply BONUS_BUY filtering
            return resultArray.filter(
                item => item.features && item.features.some(feature => feature.id === 'BONUS_BUY')
            )
        }

        if (category.value == 0) {
            return resultArray
        }

        if (category.value == 1) {
            const ids = dashboardStore.popularGames.map(item => item.id)
            return resultArray.filter(item => ids.includes(item.id))
        }

        if (category.value == 2) {

            // Create a copy of the array and then sort it

            return [...resultArray].sort(
                (a, b) =>
                    new Date(a.release_date?.slice(0, 10)) - new Date(b.release_date?.slice(0, 10))
            )
        }

        if (category.value == 3) {
            return resultArray.filter(item => item.category.includes('SLOT'))
        }

        if (category.value == 4) {
            return resultArray.filter(item => item.category.includes('LIVECASINO'))
        }
        if (category.value == 5) {
            return resultArray.filter(item => item.features.filter(item => item.id == 'BONUS_BUY'))
        }

        if (category.value == 6) {
            return resultArray.filter(item => item.category.includes('TABLEGAME'))
        }

        return resultArray
    }
    // For other categories, return the unfiltered game list
)

const nextPage = () => {
    if (dashboardStore.pageDashboardReal == itemBatches.value.length) dashboardStore.hasNext = false
    dashboardStore.plusPageDashboard(1)
}

const prevPage = () => {
    dashboardStore.plusPageDashboard(-1)
}

const category = computed(() => dashboardStore.category)
const images = ref([
    require('../assets/banner-image-2.jpg'),
])

let saveInterval
let externInterval
let worker

const nextLink = ref([1])


// Function to stop the Web Worker
function stopWorker() {
    if (worker) {
        worker.terminate()
        worker = null
    }
}

// Function to save data using the Web Worker
const saveData = async () => {
    const queryString =
        nextLink.value[0] != 1 && nextLink.value.length != 0
            ? nextLink.value[0].href.split('?')[1]
            : ''

    const urlLink = `https://uno.thegamehub.gg:3009/gamelist?${queryString == '' ? 'size=500' : queryString
        }`
    axios({
        method: 'get',
        headers: {
            sign: '7qf1oyipq5m7od27n75kli2y8c7iek3x'
        },
        url: urlLink
    })
        .then(res => {
            nextLink.value = res.data.links
            dashboardStore.setSearchList(res.data.items)

            if (nextLink.value.length != 0) {
                nextLink.value[0].href = nextLink.value[0].href.replace('size=100', 'size=500')
                console.log(res.data)
                if (worker) {
                    worker.postMessage({
                        data: res.data // Pass the data to the worker
                    })
                }
            }
        })
        .then(() => {
            getDataFromIndexedDB()
                .then(data => {
                    const uniqueArray = data.filter(
                        (item, index, self) => index === self.findIndex(t => t.id === item.id)
                    )
                    dashboardStore.setSearchList(uniqueArray)
                })
                .catch(error => {
                    console.error('Error fetching data from IndexedDB:', error)
                })
        })
}
const urlLink = `https://uno.thegamehub.gg:3009/gamelist?size=500`
console.log(urlLink)
// axios({
//     method: 'get',
//     headers: {
//         sign: '7qf1oyipq5m7od27n75kli2y8c7iek3x'
//     },
//     url: urlLink
// })
//     .then(res => {
//         nextLink.value = res.data.links
//             if (nextLink.value.length != 0) {
//                 nextLink.value[0].href = nextLink.value[0].href.replace('size=100', 'size=500')
//                 console.log(res.data)
//                 if (worker) {
//                     console.log(res.data)
//                     worker.postMessage({
//                         data: res.data // Pass the data to the worker
//                     })
//                 }
//             }
//         })
//         .then(() => {
//             getDataFromIndexedDB()
//                 .then(data => {
//                     console.log(data.length)
//                     dashboardStore.setSearchList(data)
//                 })
//                 .catch(error => {
//                     console.error('Error fetching data from IndexedDB:', error)
//                 })
//                 .catch(error => {
//                     console.error('Error fetching data:', error)
//                 })
//         })


function getDataFromIndexedDB() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('MyDatabase', 2) // Ensure the correct version is used

        request.onupgradeneeded = function (event) {
            const db = event.target.result

            if (!db.objectStoreNames.contains('games')) {
                console.log('Creating object store: games')
                db.createObjectStore('games', { keyPath: 'id' })
            } else {
                console.log('Object store games already exists.')
            }
        }

        request.onsuccess = function () {
            const db = request.result
            console.log('Database opened successfully:', db)

            try {
                const transaction = db.transaction('games', 'readonly')
                const store = transaction.objectStore('games')
                const getRequest = store.get(1) // Assuming you're using a single record with key `1`

                getRequest.onsuccess = function () {
                    if (getRequest.result) {
                        resolve(getRequest.result.data)
                        console.log('here', getRequest.result.data)
                    } else {
                        resolve([]) // If no data is found, return an empty array
                    }
                }

                getRequest.onerror = function () {
                    reject('Failed to retrieve data.')
                }
            } catch (error) {
                console.error('Transaction error:', error.message)
                reject('Failed to create transaction: ' + error.message)
            }
        }

        request.onerror = function (event) {
            reject(`Failed to open database: ${event.target.errorCode}`)
        }
    })
}
const numitems = ref(0)
const games_ = ref([]) // Reactive variable to store the games data
const error_ = ref(null);
const amount = ref()
let intervalId;

// Fetch games from Supabase
// const fetchGames = async () => {
//     const { data: gamesData, error: fetchError } = await supabase
//         .from('qt_games')
//         .select('*');
//     console.log("gamesdata", gamesData)
//     if (fetchError) {
//         error_.value = fetchError.message;
//     } else {
//         console.log("data", gamesData)
//         games_.value = gamesData;
//     }
// };

const isMounted = ref(false);
onMounted(async () => {

    calculateScrollAnimation();
    watch(
        () => repeatedItems.value,
        () => {
            calculateScrollAnimation(); // Recalculate if repeatedItems changes
        },
        { immediate: true }
    );

    dashboardStore.getWithrawal24h()
    getTournaments()
    dashboardStore.getLastPlayed()
    tableGames()
    newGames()
    slotGames()
    liveCasino()
    bonusGames()

    calculateScrollAnimation();
    //await fetchGames()
    if (dashboardStore.gameUrl == '') {
        console.log("COMPONENT MOUNETD")
        dashboardStore.getGameTransactions()
        dashboardStore.getMostPopularList(null, '', 'dashboard', '')
        dashboardStore.getBalance()

        console.log("AGGREGA", aggregatedBetsAndPayouts)

        intervalId = setInterval(() => {
            dashboardStore.getGameTransactions()
        }, 40000); // 120000 milliseconds = 2 minutes
        isMounted.value = true
    }
})

async function handleEscKey(event) {
    if (event.key === 'Escape' || event.keyCode === 27) {
        //window.location.reload();
        closeIframe()
        // Perform your action here, e.g., close a modal or clear input
        console.log('ESC pressed');
    }
}

onUnmounted(async () => {
    //window.location.reload();
    calculateScrollAnimation();
    window.removeEventListener('keydown', handleEscKey);
    // Clear the interval when the component is unmounted to prevent memory leaks
    if (saveInterval) {
        clearInterval(saveInterval)
    }
    clearInterval(intervalId);
    // Stop the Web Worker
    stopWorker()
});

const resetStyle = computed(() => {
    return {
        transform: `translateX(0%)`,
        transition: 'transform 0.5s ease' // Apply transition effect
    }
})


const sliderStyle = computed(() => {
    return {
        transform: `translateX(-${currentIndex.value * 100}%)`,
        transition: 'transform 0.5s ease' // Apply transition effect
    }
})

const canSlideLeft = computed(() => {
    return currentIndex.value > 0
})

const canSlideRight = computed(() => {
    if (dashboardStore.gameList.items)
        return (currentIndex.value + 1) * 7 < dashboardStore.gameList.items.length
    else return false
})

function showBackButton() {
    // window.location.reload();
    window.parent.postMessage("showBackButton", "*");
}
const openIframe = item => {
    console.log("GAMEID", item)
    showIframe.value = true
    dashboardStore.setLoading(true)
    console.log("LOADINGST", dashboardStore.loading)
    dashboardStore.getGameUrl(item)
    showBackButton()
    if (dashboardStore.showIframe == false) {
        dashboardStore.setShowIframe(true)

    }
}


const repeatedItems = computed(() => {
    const items = Array.isArray(aggregatedBetsAndPayouts.value) ? aggregatedBetsAndPayouts.value : [];
    // Duplicate the array for seamless animation
    return [...items, ...items];
})

const openIframe1 = item => {
    console.log("GAMEID", item)
    showIframe.value = true
    dashboardStore.setLoading(true)
    console.log("LOADINGST", dashboardStore.loading)
    dashboardStore.getGameUrl(item.id)
    showBackButton()
    if (dashboardStore.showIframe == false) {
        dashboardStore.setShowIframe(true)

    }
}


const slideLeft = () => {
    currentIndex.value--
}




const slideRight = () => {
    currentIndex.value++
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.infinite-container {
    overflow: hidden;
    /* Adjust based on your layout */
}

.image-row {
    display: flex;
    white-space: nowrap;
    animation: scroll linear infinite;
    animation-duration: var(--scroll-duration, 10s);
    /* Default duration of 10s if not set */
}

.player-image {
    width: 100px;
    /* Adjust based on image size */
    height: 100px;
    margin-right: 10px;
}


@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-1 * var(--scroll-distance)));
    }
}

h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

.image-border {
    border-radius: 20px;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}

.outerbox {
    border-color: theme('colors.border-dropdown-1');
    background: theme('colors.dropdown-1');
}

.bubble-border {
    padding: 2px;
    border-radius: 22px;
    background: linear-gradient(180deg, #4af3ff 0%, #1d99a2 100%);
}

.bubble-inner-2 {
    background: linear-gradient(180deg, #00e6f6 0%, #08777e 100%);
}

.bubble-inner {
    background: linear-gradient(180deg, #00e6f6 0%, #08777e 100%);
    border-radius: 20px;
}

.dollar-text {
    background-image: linear-gradient(to bottom, #4af3ff, #1d99a2);
}

.welcome {
    background: linear-gradient(to top right, #853d32, #2b2f4e);
}

.welcome::before {
    content: '';
    background: linear-gradient(180deg, #4e3148 50%, #121b3e);
    box-shadow: 0px 0px 40px 0px #e75ec940;
}

.welcome-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #853d32 100%);
}

.welcome-inner {
    background: linear-gradient(135deg, #2b0033, #6e007a);
}

.deposit-border {
    background: linear-gradient(to bottom left, #2b2f4e 0%, #7b5635 100%);
}

.refer-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #50357b 100%);
}

.refer-inner {
    background: linear-gradient(180deg, #121b3e 6.58%, #460e68 100%);
}

.promotions-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #6d3058 100%);
}

.promotions-inner {
    background: linear-gradient(180deg, #121b3e 6.58%, #4a1a34 100%);
}

.deposit-inner {
    background: linear-gradient(180deg, #121b3e 6.58%, #403135 100%);
}

/* css error */

.vip-border {
    background: linear-gradient(180deg, #2b314e 0%, #1d8cca 100%);
}

.vip-inner {
    background: linear-gradient(180deg, #192b5b 19.44%, #044966 114.96%);
}

.batch {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
}

.loyalty-border {
    background: linear-gradient(180deg, #2b2f4e 0%, #853d32 100%);
}

.loyalty-inner {
    background: linear-gradient(to bottom left, #121b3e 6.58%, #4e3133 100%);
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.gradient-box {
    border-radius: 20px;
    /* Adjust this value to change the border radius */
    padding: 2px;
    /* Adjust to match the border width */
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-enter-active,
.slider-leave-active {
    transition: transform 0.5s ease;
}

.flex {
    display: flex;
    transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.slider-enter-from,
.slider-leave-to {
    transform: translateX(100%);
}

.slider-leave-from,
.slider-enter-to {
    transform: translateX(0);
}

.slider {
    transition: transform 0.5s ease;
}

.slider-item {
    text-align: center;
}
.inner-box {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.5rem;
    text-align: center;
}

.left-full {
    left: -100%;
}

.carousel {
    position: relative;
}

.carousel-inner {
    position: relative;
    height: 100%;
}

.filter-white {
    filter: brightness(0) saturate(100%) invert(100%) sepia(55%) saturate(150%) hue-rotate(242deg)
        brightness(110%) contrast(100%);
}

.fullscreen-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.carousel-item {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: transform 2s ease-in-out;
}

.carousel-item img {
    display: block;
    width: 100%;
    height: 100%;
    object-cover: cover;
}
</style>
