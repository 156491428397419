// src/main.ts
import { createApp, reactive, ref } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import axios from 'axios'
import './assets/tailwind.css' // Import Tailwind CSS
import { useDashboardStore } from './store/dashboardStore'

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import {
    getAuth,
    signOut,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signInWithCustomToken
} from 'firebase/auth'
import { getDocs, getFirestore, limit, orderBy, query, where } from 'firebase/firestore'
import { getFunctions } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'
import { doc, getDoc, collection } from 'firebase/firestore'
import LoadingVue from './components/LoadingVue.vue'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const app = createApp(App)
interface ServiceWorkerRegistrati {
    sync: {
        register(tag: string): Promise<void>
    }
}
// Firebase configuration using environment variables
const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

app.use(createPinia())
const dashboardStore = useDashboardStore()
// Firebase configuration using environment variables

import VueLazyload from 'vue-lazyload-next'
import { useRoute, useRouter } from 'vue-router'

import { useAuthStore } from './store/authStore'

const app_ = initializeApp(firebaseConfig)
initializeApp(firebaseConfig)
const nextLink = ref('')
let balance: any = {}
let depositsDocs: any = ''
let withDocs: any = ''
let coinSupported = [] as any // Declare the variable outside the function for exporting
let qtSelectedCurrency: any = ''
let data: any = []
export const db = getFirestore(app_) //to pass in the doc(db, "path");
export const auth = getAuth(app_)

export const Functions = getFunctions(app_) //to pass in the HttpsCallable
export const analytics = getAnalytics(app_)

import { createClient } from '@supabase/supabase-js'
const supabaseUrl = process.env.VUE_APP_SUPABASE_URL ? process.env.VUE_APP_SUPABASE_URL : ''
const supabaseAnonKey = process.env.VUE_APP_SUPABASE_ANON_KEY
    ? process.env.VUE_APP_SUPABASE_ANON_KEY
    : ''
export const supabase = createClient(supabaseUrl, supabaseAnonKey)

// Use Vue Router
app.use(router)

// Use vue-lazyload-next
app.use(
    VueLazyload,
    app.use(VueLazyload, {
        preLoad: 1.3, // Preload images that are just off-screen (adjust as needed)

        attempt: 1, // Number of retry attempts
        observer: true, // Use IntersectionObserver
        observerOptions: {
            root: null, // Use the viewport as the root
            rootMargin: '0px 100% 0px 0px', // Extend observation area to the right
            threshold: 0.1 // Trigger loading earlier
        }
    })
)

app.mount('#app')
await initApp()
const depRef = collection(db, `users/${auth.currentUser?.uid}/np-dep-history`)
const q = query(depRef, orderBy('date', 'desc'), limit(10))

depositsDocs = await getDocs(q)
const depositDocs = depositsDocs.docs.map((doc: any) => ({
    id: doc.id, // Document ID
    ...doc.data() // Document data
}))
const withRef = collection(db, `withdrawals`)

const q2 = query(
    withRef,
    where('uid', '==', auth.currentUser?.uid),
    orderBy('date', 'desc'),
    limit(10)
)
withDocs = await getDocs(q2)
// Extract documents
const docsData = withDocs.docs.map((doc: any) => ({
    id: doc.id, // Document ID
    ...doc.data() // Document data
}))

// Correctly reference the Firestore collection and document

// Async function to fetch the data

async function fetchGamesData() {
    const dashboardStore = useDashboardStore()
}

dashboardStore.setLoading(false)
export const exampleRegister = async (email: string, passw: string) => {
    try {
        await createUserWithEmailAndPassword(auth, email, passw)
    } catch (error) {
        alert(error)
    }
}

const balanceState = reactive({
    balance: null
})

// Provide the balance state to the rest of the app
app.provide('balanceState', balanceState)
export const exampleLogin = async (email: string, passw: string) => {
    try {
        await signInWithEmailAndPassword(auth, email, passw)
            .then(userCredential => {
                const user = userCredential.user

                dashboardStore.setErrors('')
            })
            .catch(error => {
                const errorCode = error.code
                const errorMessage = error.message

                dashboardStore.setErrors(error.code)
            })
        sessionStorage.setItem('login', 'true')
    } catch (error) {
        //handle error
        sessionStorage.setItem('login', 'false')
    }
}
export const logOut = async () => {
    await signOut(auth)
    sessionStorage.setItem('login', 'false')
}

async function initApp() {
    // Get the part of the URL after the hash
    dashboardStore.setLoading(true)
    const hash = window.location.hash
    let tokenAuth: any = ''
    // Check if there are any parameters after the hash
    const hashParamsIndex = hash.indexOf('?')
    if (hashParamsIndex !== -1) {
        // Extract the query string from the hash
        const queryString = hash.slice(hashParamsIndex + 1)

        // Use URLSearchParams to parse the query string
        const params = new URLSearchParams(queryString)

        // Get the tokenAuth parameter
        tokenAuth = params.get('tokenAuth')
    } else {
        console.log('No URL parameters found.')
    }
    await signInWithCustomToken(auth, tokenAuth).then(async () => {
        try {
            const playerUid = auth.currentUser?.uid // Get the UID of the authenticated user

            const userRef = doc(db, `users/${playerUid}`) // 'db' should be your Firestore instance

            const userData = await getDoc(userRef)

            balance = await userData.data()?.balances

            qtSelectedCurrency = await userData.data()?.qtSelectedCurrency
            console.log('qtSee', qtSelectedCurrency)
            // Fetch the games data first
            data = await fetchGamesData()
            // Fetch base data after games data is initialized
            const baseDataRef = doc(db, 'aBase/baseInfo')
            const baseData = await getDoc(baseDataRef)
            console.log('BASEDATA', baseData.data())

            coinSupported = (await baseData.data()?.coinSupported) || {} // Assign the fetched value to coinSupported
            dashboardStore.setMaxWithrawal(baseData.data()?.maxWithdrawal24Hours)
            dashboardStore.setCoinSupported(coinSupported)
            console.log('COINSUPPPRTED', coinSupported, qtSelectedCurrency)
            dashboardStore.setLoading(false)
            // Once data is fetched, you can mount the app
        } catch (error) {
            console.error('Error initializing app:', error)
        }
    })
}
console.log('COINSUPPPRTED', coinSupported)
// Shared reactive key
export { coinSupported }
export { data }
export { balance }
export { docsData }
export { depositDocs }
export { qtSelectedCurrency }

