<template>
    <div class="block px-4 pb-11" style="grid-template-columns: 50vw 50vw">
        <CustomAlert v-if="showCustomAlert" type="success" message="Text Copied to Clipboard!" @close="onAlertClose" />
        <div
            class="flex h-11 mt-6 mb-3 items-center rounded-lg text-[#6D758A] text-sm font-righteous justify-evenly bg-[#131931] border-[#1E284A] border-[2px]">
            <div style="width:50%" @click="handleState(1)">
                <button v-if="state != 1">Deposit</button>
                <ButtonBox v-if="state == 1" :text="'Deposit'" />
            </div>
            <div style="width:50%" @click=" handleState(2)">
                <button v-if="state != 2">Withdraw</button>
                <ButtonBox v-if="state == 2" :text="'Withdraw'" />
            </div>

        </div>

        <div class="flex" v-if="state == 1 && coinSupported_ && Object.keys(coinSupported_).length > 0">

            <div class="flex flex-col" style="width:100%">
                <div>
                    <!-- <p class="text-text-modal text-[13px] font-bold text-start pb-1">Currency</p>

                    <TextBoxVue :icon="'dollar-white'" :isDropDown="true" :itemsList="['USD', 'EUR', 'CAD']"
                        :type="'text'" :green="greenTick" @updateField="handleUpdateCoin" /> -->
                    <p class="text-text-modal pt-2 text-[13px] font-bold text-start pb-1">
                        Select Token to Deposit
                        <!-- <span>{{ crypto.chains ? crypto.chains.map(chain =>
                            result += `(${chain.coin}): ${chain.min_wid} `) :
                            null }}</span> -->{{ crypto.chains ?
                        `(Min ${crypto.chains[0].min_wid} ${crypto.chains[0].coin})` : null }}
                    </p>
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <div v-for="(coin, index) in coinSupported_" :key="index"
                        :class="coin.symbol == crypto.symbol ? 'wrapper-2' : ''">
                        <button v-if="coin.symbol != 'GHUB'" :class="coin.symbol == crypto.symbol ? 'save-button' : ''"
                            @click="handleUpdateCrypto(coin)"
                            class="flex items-center justify-center rounded-lg w-full h-12 bg-profile-deposit border-2 border-profile-deposit">
                            <img class="px-2" width="45px" :src="coin.img" alt="" /><span
                                :class="coin.symbol == crypto.symbol ? 'text-white' : 'text-text-modal'"
                                class="text-xs ">{{ coin.symbol }}</span>
                        </button>
                    </div>




                </div>
                <div v-if="crypto.chains && crypto.chains.length > 0" class=" pt-2">
                    <p class="text-text-modal text-[13px] font-bold text-start pb-1">Select Chain/Network</p>
                    <!-- <TextBoxVue :icon="'dollar-white'" :isDropDown="true"
                        :itemsList="crypto.chains.map(chain => chain.name == 'Ethereum' ? 'Ethereum (ERC-20)' : chain.name)"
                        :type="'text'" :green="greenTick" @updateField="handleUpdateCrypto_alt" /> -->
                    <div class="grid grid-cols-2 gap-2">
                        <div v-for="(chain, index) in crypto.chains" :key="index"
                            :class="chain.coin == crypto_selected ? 'wrapper-2' : ''">

                            <button :class="chain.coin == crypto_selected ? 'save-button' : ''"
                                @click="handleUpdateCrypto_alt(chain.coin)"
                                class="flex items-center justify-center rounded-lg w-full h-12 bg-profile-deposit border-2 border-profile-deposit">
                                <img class="px-2" width="45px"
                                    :src="chain.name == 'Arbitrum' ? require('../../assets/Arbitrum.png') : crypto.img"
                                    alt="" /><span
                                    :class="chain.coin == crypto_selected ? 'text-white' : 'text-text-modal'"
                                    class="text-xs ">{{ chain.name }}</span>
                            </button>
                        </div>




                    </div>
                </div>
            </div>

        </div>
        <div class="loading-dots pt-4" v-if="addressLoading == true && state == 1">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
        <div style="margin-bottom: 43px;
    height: 100%;"
            v-if="dashboardStore.crypto_address.data && addressLoading == false && state == 1 && crypto_selected != ''">

            <div class="text-start flex items-center pt-3 text-xs w-full justify-between ">

                <div class="flex  pr-1" style="font-size:10px"><span class="pr-2">Address:</span>
                    <input class="text-xs" type="text" style="all: unset; min-width:250px; font-weight: bold;"
                        id="myInput"
                        :value="dashboardStore.crypto_address.data ? dashboardStore.crypto_address.data.address : null"
                        readonly />
                    {{ indexName && crypto.chains[indexName] ? '(Min' + crypto.chains[indexName].min_wid + ' ' +
    crypto.chains[indexName].coin + ')' :
    null }}
                </div>
                <div class="flex w-[full] justify-end"><button
                        class="px-2  rounded-lg py-1 border-profile-deposit text-sm bg-profile-deposit border-[2px]"
                        @click="myFunction"><img width="20" style="min-width: 20px" src="../../assets/contentCopy.svg"
                            alt="">
                    </button></div>

            </div>
            <div class="flex flex-start" style="font-size:10px">Min: {{ min_withrawal + ' ' + crypto_selected }}</div>

            <div class="flex justify-center pt-3">
                <Qrcode size="150" :value="qrText" />

            </div>
            <div class="text-xs pt-3">
                <div>Expiration Date: {{ validity }} <span class="pl-2">UTC</span></div>
                <div>Note: <b>Do NOT deposit</b> after the expiration date. Funds sent after this date won't be
                    credited.
                </div>
            </div>

        </div>
        <div class="flex flex-row" v-if="state == 2">

            <div class="flex flex-col justify-end" style="width:100%">
                <p class="text-text-modal pt-2 text-[13px] font-bold text-start pb-1">
                    Balance
                </p>
                <div class="flex w-full justify-end">
                    <div v-if="filteredCoinBalances" ref="compRef" style="height: 38px; border-radius: 12px; width:100%"
                        class="w-36  font-roboto font-extrabold text-center flex justify-end items-center bg-profile-deposit border-profile-deposit">
                        <div @click="toggleDropdown" class="flex justify-end w-[100%] text-base">
                            <span v-if="filteredCoinBalances.length != 0" class="text-white pl-3 flex items-center"><img
                                    class="min-w-5 min-h-5" width="20" height="20 "
                                    v-lazy="filteredCoinBalances && filteredCoinBalances[0].img" alt=""></span>
                            <button id="dropdown" class="flex w-full items-center group peer">

                                <span class="pl-2 text-xs text-white">
                                    {{

                                        (() => {
                                            const multiplierIndex = dashboardStore.multipliers.findIndex(obj =>
                                                obj[crypto.symbol]
                                                !== undefined);
                                            if (multiplierIndex === -1) {
                                                amount // Fallback value when no match is found
                                            }
                                            return (
                                                (amount ? amount : 0)
                                            )
                                        })()
                                    }}
                                </span>
                                <!-- <div class="flex items-center  justify-end ml-auto pr-2 "><svg
                                        :class="{ 'rotate-90': isRotated }"
                                        class="w-4 h-4 flex justify-end  text-arrow-color group-open:rotate-90"
                                        xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor"
                                        viewBox="0 0 16 16">
                                        <path fill-rule="evenodd"
                                            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z">
                                        </path>
                                    </svg></div> -->
                            </button>

                            <!-- 
                            <ul v-if="isOpen == true" class="dropdown-menu bg-profile-deposit text-xs text-white">
                                <li :class="index == 0 ? 'dropdown-item text-xs rounded-t-lg' : index == coinBalances.length - 1 ? 'dropdown-item rounded-b-lg' : 'dropdown-item'"
                                    v-for="(coin, index) in coinBalances" :key="index" @click="selectCoin(coin, index)">

                                    <img :src="coin.img" :alt="coin.name" width="20" height="20" />

                                    <span class="text-[11px]">{{
                                        (coin.amount.toFixed(5)) }}</span>

                                </li>
                            </ul> -->

                        </div>

                        <!-- <button style="width:100%" class="flex text-[21px]  h-[100%] pb-0 text-[white] pr-3 justify-end">+
                    </button> -->
                        <!--To remove-->
                    </div>
                </div>

                <div>
                    <div v-if="crypto.chains && crypto.chains.length > 0" class="pt-2">
                        <p class="text-text-modal text-[13px] font-bold text-start pb-1">Withdrawal Address</p>
                        <input
                            class="w-full rounded-lg py-[8px] bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] focus:ring-2 pl-[50px] pr-[26px]"
                            type="text" @input="updateAddress" v-model="widthdraw_address">
                    </div>
                    <div v-if="crypto.chains && crypto.chains.length > 0" class="pt-2">
                        <p class="text-text-modal text-[13px] font-bold text-start pb-1">Amount</p>
                        <input
                            class="w-full rounded-lg py-[8px] bg-profile-deposit text-[14px] border-profile-deposit text-white focus:border-[#ff000000] focus:outline-none focus:ring-[#ff000000] focus:ring-2 pl-[50px] pr-[26px]"
                            type="text" @input="updateAmount" v-model="amount_value">
                        <span class="text-red-600 flex pt-1 font-bold text-xs justify-start"
                            v-if="check24hwithdraw == true">
                            Max {{ dashboardStore.maxWithdrawal }}$ withdrawal per day
                        </span>
                        <span class="text-red-600 flex pt-1 font-bold text-xs justify-start"
                            v-if="Number(amount_value) > currentBalance">
                            You're trying to withdraw more than your current balance
                        </span>

                    </div>
                    <!-- <p class="text-text-modal text-[13px] font-bold text-start pb-1">Currency</p>

                    <TextBoxVue :icon="'dollar-white'" :isDropDown="true" :itemsList="['USD', 'EUR', 'CAD']"
                        :type="'text'" :green="greenTick" @updateField="handleUpdateCoin" /> -->
                    <p class="text-text-modal pt-2 text-[13px] font-bold text-start pb-1">
                        Select Token to Withdraw
                        <!-- <span>{{ crypto.chains ? crypto.chains.map(chain =>
                            result += `(${chain.coin}): ${chain.min_wid} `) :
                            null }}</span> -->{{ crypto.chains ?
                                `(Min ${crypto.chains[0].min_wid} ${crypto.chains[0].coin})` : null }}
                    </p>
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <div v-for="(coin, index) in coinSupported_" :key="index"
                        :class="coin.symbol == crypto.symbol ? 'wrapper-2' : ''">

                        <button v-if="coin.symbol != 'GHUB'" :class="coin.symbol == crypto.symbol ? 'save-button' : ''"
                            @click="handleUpdateCrypto(coin)"
                            class="flex items-center justify-center rounded-lg w-full h-12 bg-profile-deposit border-2 border-profile-deposit">
                            <img class="px-2" width="45px" :src="coin.img" alt="" /><span
                                :class="coin.symbol == crypto.symbol ? 'text-white' : 'text-text-modal'"
                                class="text-xs ">{{ coin.symbol }}</span>
                        </button>
                    </div>




                </div>

                <div v-if="crypto.chains && crypto.chains.length > 0" class=" pt-2">
                    <p class="text-text-modal text-[13px] font-bold text-start pb-1">Select Chain/Network</p>
                    <div class="grid grid-cols-2 gap-2">
                        <div v-for="(chain, index) in crypto.chains" :key="index"
                            :class="chain.coin == crypto_selected ? 'wrapper-2' : ''">

                            <button :class="chain.coin == crypto_selected ? 'save-button' : ''"
                                @click="handleUpdateCrypto_alt(chain.coin)"
                                class="flex items-center justify-center rounded-lg w-full h-12 bg-profile-deposit border-2 border-profile-deposit">
                                <img class="px-2" width="45px"
                                    :src="chain.name == 'Arbitrum' ? require('../../assets/Arbitrum.png') : crypto.img"
                                    alt="" /><span
                                    :class="chain.coin == crypto_selected ? 'text-white' : 'text-text-modal'"
                                    class="text-xs ">{{ chain.name }}</span>
                            </button>
                        </div>




                    </div>

                </div>
                <button @click="handleWithdraw"
                    :disabled="amount_value == '' || widthdraw_address == '' || check24hwithdraw == true">
                    <ButtonBox class="mt-6 mb-8" v-if="crypto.chains && crypto.chains.length > 0" :text="'Withdraw'"
                        :disabled="amount_value == '' || widthdraw_address == '' || crypto_selected == '' || check24hwithdraw == true || Number(amount_value) > currentBalance" />
                </button>
            </div>
        </div>

    </div>
</template>

<script setup>
import TextBoxVue from '@/components/form/TextBox.vue'
import Qrcode from 'qrcode.vue'
import moment from 'moment'

import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import CustomAlert from '../CustomAlert.vue'
import { httpsCallable } from 'firebase/functions'
import { Functions } from '@/main'
import { useDashboardStore } from '@/store/dashboardStore'
import { coinSupported, fetchUserBalance, balance } from '@/main'
import ButtonBox from '../form/ButtonBox.vue'
const state = ref(1)
const showCustomAlert = ref(false);

const showAlert = () => {
    showCustomAlert.value = true;
};

const onAlertClose = () => {
    showCustomAlert.value = false;
};
const handleState = val => {
    crypto.value = '',
        crypto_selected.value = ''
    state.value = val

}
const compRef = ref(null);

const currentBalance = computed(() => {
    const multiplierIndex = dashboardStore.multipliers.findIndex(obj =>
        obj[crypto.value.symbol]
        !== undefined);
    return (

        (amount.value ? amount.value : 0)
    )
})
const check24hwithdraw = computed(() => {

    console.log("CHECK24h", dashboardStore.usdWithdrawedLast24Hours.usdWithdrawedLast24Hours, Number(amount_value.value), dashboardStore.maxWithdrawal)
    return dashboardStore.usdWithdrawedLast24Hours.usdWithdrawedLast24Hours + Number(amount_value.value) * selectedMultiplier.value > dashboardStore.maxWithdrawal ? true : false
})
// Detect clicks outside the component
const handleClickOutside = (event) => {
    if (compRef.value && !compRef.value.contains(event.target)) {
        // Perform your action here
        isOpen.value = false
        isRotated.value = false
        console.log("Clicked outside the component");
    }
};


const selectedMultiplier = computed(() => {
    const result = dashboardStore.multipliers.find((item) => item[selectedOption.value] !== undefined)
    return result ? result[selectedOption.value] : null
})
onMounted(() => {

    document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {

    document.removeEventListener("click", handleClickOutside);
});

const isOpen = ref(false);
const error_user = ref(false)
const coin = ref('')
const isRotated = ref(false)
const crypto = ref('')
const addressLoading = ref(false)
const crypto_selected = ref('')
const payment_selected = ref('$25')
const amount_selected = ref(0)
const dashboardStore = useDashboardStore()
const greenTick = ref(false)
const min_withrawal = ref(0)
const selectedOption = ref('ETH');
const selected_index = ref(0)
const coinBalances = computed(() => {
    let coinSupported2
    if (Object.keys(coinSupported).length != 0 || coinSupported.length != 0) {
        console.log("1", coinSupported)
        coinSupported2 = coinSupported
    }
    if (Object.keys(coinSupported_.value).length != 0 || coinSupported_.value.length != 0) {
        console.log("2", coinSupported_.value)

        coinSupported2 = coinSupported_.value
    }
    if (Object.keys(coinSupported).length != 0 || coinSupported.length != 0) {
        console.log("3", coinSupported)
        coinSupported2 = coinSupported
    }


    if (dashboardStore.coinSupported.length != 0) {
        console.log(coinSupported, coinSupported2.length, 'COINSUPPORTED')

        return dashboardStore.coinSupported.length != 0 && dashboardStore.coinSupported.filter((coin) => coin.symbol.toLowerCase() !== "ghub") // Exclude GHUB
            .map((coin) => {
                const balance_ = dashboardStore.balance_selected ? dashboardStore.balance_selected[coin.symbol.toLowerCase()]?.amount ?? 0.00 : 0.00;
                console.log(balance)
                return {
                    symbol: coin.symbol,
                    name: coin.name,
                    img: coin.img,
                    amount: balance_,
                    multiplier: dashboardStore.multipliers[coin.symbol]
                };
            });
    } else return null

});
const selectCoin = (coin, index, value) => {
    selected_index.value = index
    console.log(coin)
    selectedOption.value = coin.symbol;
    dashboardStore.setSelectedCoin(coin.symbol)
    dashboardStore.selectGameCurrency(coin.symbol)

    isOpen.value = false;
};

const toggleDropdown = () => {
    isOpen.value = !isOpen.value;
    isRotated.value = !isRotated.value;

    console.log("open", isOpen.value)
};


const filteredCoinBalances = computed(() => {
    console.log("COINBALANCES", coinBalances.value)
    return coinBalances.value && coinBalances.value.filter(coin =>
        coin.symbol.includes(crypto.value.symbol ? crypto.value.symbol : 'ETH')
    );
});

const amount = computed(() => {
    return coinBalances.value && coinBalances.value.find(obj => crypto.value.symbol == null ? 'ETH' : obj.symbol === crypto.value.symbol)?.amount;
})
const myFunction = async () => {
    const inputElement = document.getElementById("myInput");
    const textToCopy = inputElement.value;

    // Always use fallback for live environments
    fallbackCopyTextToClipboard(textToCopy);
};

function fallbackCopyTextToClipboard(text) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    textArea.style.left = "-9999px";
    document.body.appendChild(textArea);
    textArea.select();
    try {
        document.execCommand("copy");
        // showAlert()
        console.log("Fallback: Text copied successfully");
    } catch (err) {
        console.error("Fallback: Unable to copy text", err);
        alert("Failed to copy text.");
    }
    document.body.removeChild(textArea);
}

const handleWithdraw = async () => {
    const funct = httpsCallable(Functions, "widthdrawNowPayments");
    const data = {
        amount: Number(amount_value.value),
        coin: crypto_selected.value,
        wallet_address: widthdraw_address.value,
    };

    console.log("DATA", data)
    try {
        dashboardStore.setLoading(true)
        const res = await funct(data);
        if (!res.data.success) {
            dashboardStore.setLoading(false)
            alert(res.data.msg)
            return


        } else if
            (res.data.needManual == true) {
            alert("For security reasons, your withdrawal was sent for manual approval.")

            dashboardStore.setLoading(false)
            throw { msg: res.data.msg };
        }


        dashboardStore.setLoading(false)
        fetchUserBalance()
        dashboardStore.setLoading(false)
    } catch (err) { alert(err), dashboardStore.setLoading(false), fetchUserBalance() }


}
const handleUpdateCoin = e => {
    coin.value = e
    dashboardStore.setCurrency(e)
}
const coinSupported_ = computed(() => {
    return coinSupported
})
const handleUpdateCrypto = e => {
    crypto.value = e
    crypto_selected.value = ''
}

const formattedValidity = () => {
    return moment(validity.value).format('YYYY-MM-DD hh:mm:ss');

}

const widthdraw_address = ref('')
const amount_value = ref('')
const qrText = computed(() => {
    return dashboardStore.amount ?
        `${dashboardStore.crypto.name}:
    ${dashboardStore.crypto_address.address}
    ?amount=${dashboardStore.amount}` : `
    ${dashboardStore.crypto.name}:
    ${dashboardStore.crypto_address.address}`
})


const indexName = computed(() => {
    return crypto.value != '' ? crypto.value.chains.findIndex(chain => crypto_selected.value.includes(chain.name)) : 0;

})
const validity = ref('')

const updateAddress = (e) => {
    console.log("address", e.text)
    widthdraw_address.value = e.target.value
}
const updateAmount = (e) => {
    // Get the current input value
    let value = e.target.value;
    // Allow only numbers and up to 4 decimal places
    value = value.replace(/[^0-9.]/g, '');           // Remove non-numeric characters except for .
    value = value.replace(/(\..*)\./g, '$1');         // Allow only one decimal point
    value = value.replace(/^0+(\d)/, '$1');           // Remove leading zeros

    // Restrict to 4 decimal places
    const decimalIndex = value.indexOf('.');
    if (decimalIndex !== -1) {
        value = value.slice(0, decimalIndex + 5);       // Keep only 4 digits after decimal
    }

    // Update the bound data property with the sanitized value
    amount_value.value = value;
}
const handleUpdateCrypto_alt = e => {
    addressLoading.value = true
    console.log("crypto", e)
    crypto_selected.value = e
    const symbol = findSymbolByKeyword(crypto_selected.value)
    console.log("CRYPTO", crypto_selected.value)
    const getDepositAddressNowPayment = httpsCallable(Functions, 'getDepositAddressNowPayment')
    getDepositAddressNowPayment({ currency: crypto_selected.value })
        .then(result => {
            dashboardStore.setCryptoAddress(result)
            // dashboardStore.setCryptoAddress(result)
            console.log("result", result)
            min_withrawal.value = result.data.minimum
            dashboardStore.setHeader('')
            addressLoading.value = false

            validity.value = moment(result.data.valid_until).format('YYYY-MM-DD HH:mm:ss')

        })
        .catch(error => {
            console.error('Error calling function:', error)
        })
}

const handleAmountSelected = val => {
    amount_selected.value = val
    dashboardStore.setAmount(val)
}

const handlePaymentSelected = val => {
    console.log(val)
    payment_selected.value = val
}

const handleErrorUser = e => {
    error_user.value = e
    if (e == false) {
        greenTick.value = true
    } else greenTick.value = false
}
const setModal = (val, name) => {
    dashboardStore.setModalOpen(val, name)
}
function findSymbolByKeyword(phrase) {
    console.log(phrase)
    console.log(coinSupported)
    const keywords = phrase.split(" ");
    const coin = coinSupported.find(coin =>
        coin.chains.find(coin => keywords.some(keyword => coin.name.toLowerCase().includes(keyword.toLowerCase())))

    );
    return coin ? coin.symbol : null;
}
const handleDeposit = () => {
    const getDepositAddressNowPayment = httpsCallable(Functions, 'getDepositAddressNowPayment')

    dashboardStore.setCrypto(crypto.value)

    // let coin_ =
    //     getDepositAddressNowPayment({ currency: symbol })
    //         .then(result => {
    //             dashboardStore.setCryptoAddress(result)
    //             // dashboardStore.setCryptoAddress(result)
    //             setModal(false, 'deposit-modal')
    //             console.log(amount_selected.value)
    //             dashboardStore.setHeader('')
    //             // const currency = String(dashboardStore.currency.text.toLocaleLowerCase())
    //             // const pay_currency = String(crypto_selected.value?.toLocaleLowerCase())
    //             // axios({
    //             //     method: 'post',
    //             //     url: 'https://api.nowpayments.io/v1/payment',
    //             //     headers: {
    //             //         'x-api-key': 'SFETGA9-WZQM0HR-P5KXQT2-56J1P7W'
    //             //     },
    //             //     bodu: JSON.stringify({
    //             //         price_amount: amount_selected.value,
    //             //         price_currency: currency,
    //             //         pay_currency: pay_currency
    //             //     })

    //             // }).then(response => {
    //             //     console.log(response)
    //             // })
    //             setModal(true, 'payment-modal')
    //         })
    //         .catch(error => {
    //             console.error('Error calling function:', error)
    //         })
}
</script>

<style scoped>
/* Custom checkbox tick color */
input[type='checkbox']:checked {
    background-color: #131931;
    /* Green background when checked */
    border-color: #1e284a;
    box-shadow: 0px 12px 16px 0px #0000000f;
}

input[type='checkbox']:disabled:focus {
    box-shadow: none;
    border-color: #1e284a;
    /* Green border when checked */
}

input[type='checkbox']:focus {
    box-shadow: none !important;
}

.custom-checkbox input[type='checkbox'] {
    appearance: none;
    width: 16px;
    height: 16px;
    background-color: #131931;
    border: 2px solid #1e284a;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
}

input:focus {
    position: relative;
    z-index: 10;
}

.custom-checkbox input[type='checkbox']:checked {
    border-color: #1e284a;

}

.button-selected {
    background: radial-gradient(94.44% 94.44% at 50% 50%,
            rgba(255, 255, 255, 0) 39.83%,
            rgba(255, 255, 255, 0.04) 100%),
        linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0) 90.02%);
}

.save-button {
    background-color: #444;
    color: white;
    font-size: 16px;
    cursor: pointer;
    height: 44px;
    border-radius: 8px;
    border: none;
    text-align: center;
    text-decoration: none;
    box-shadow: 0px 0px 32px 0px #e99d1726;
    box-shadow: 0px 0px 12px 0px #e99d1726;
}

.dropdown {
    position: relative;
    display: inline-block;
}

svg {
    transition: transform 0.2s ease-in-out;
}

svg.rotate-90 {
    transform: rotate(90deg);
}

.dropdown-menu {
    display: block;
    position: absolute;
    top: 241px;
    right: 15px;
    min-width: 100px;
    border-radius: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-item {
    padding: 4px 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.dropdown-item img {
    margin-right: 8px;
}

.dropdown-item:hover {
    background-color: #ddd;

}
.wrapper-2 {
    border-radius: 9px;
    padding: 2px;
    background: linear-gradient(90deg, #ffbf00, #ff8000);
}

.custom-checkbox input[type='checkbox']:checked::after {
    content: '';
    position: absolute;
    top: 39%;
    left: 47%;
    transform: translate(-50%, -50%) rotate(44deg) scaleX(1);
    width: 16px;
    height: 16px;
    border: solid #1e274a;

    border-width: 0 3px 3px 0;
}

.custom-checkbox label {
    font-size: 11px;
    padding-left: 5px;
}

/* Hide default checkbox appearance and style the new one */
input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 16px;
    accent-color: #4caf50;
    /* Change tick color */

    height: 16px;
    background-color: #131931;
    /* Green background when checked */
    border-radius: 100%;
    /* Tailwind rounded */
    border-color: #1e274a;
    /* Green border when checked */
    transition: background-color 0.2s, border-color 0.2s;
    position: relative;

}

.loading-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #333;
    border-radius: 50%;
    animation: loading 0.6s infinite alternate;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes loading {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.5);
        opacity: 0.5;
    }
}
</style>
